export const SIZES = {
  mid: 'mid',
  large: 'large'
} as const;

export const POSITIONS = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  HORIZONTAL_CENTER: 'HORIZONTAL_CENTER',
  VERTICAL_CENTER: 'VERTICAL_CENTER'
} as const;

export const MOBILE_MARGIN = 4;

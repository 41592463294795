import React from 'react';
import {hot} from 'react-hot-loader/root';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {PageviewEmitter} from 'shared/components/analytics';
import makeLoadable from 'shared/helpers/makeLoadable';
import Outline from 'shared/ui/atoms/outline';
import analytics, {Variables as AnalyticsVariables} from 'candidate/analytics';
import Attribution from 'candidate/attribution';
import Filters from 'candidate/careers/search/filters/route';
import Debug from 'candidate/debug';
import ErrorBoundary, {ThrowComponent} from 'candidate/errors';
import Eeoc from 'candidate/job/submit/eeoc/page';
import Layout from 'candidate/layout';
import Previews from 'candidate/preview';
import Providers from 'candidate/providers';
import AccountMissing from 'candidate/shared/account/missing';
import CookieConsent from 'candidate/shared/cookieConsent';
import Loader from 'candidate/shared/loader';
import {
  APPLY_ROUTE,
  APPLY_ROUTE_WITHOUT_SLASH,
  EEOC,
  EEOC_WITHOUT_SLASH,
  FILTERS,
  GDPR_POLICY,
  GDPR_POLICY_WITH_VERSION,
  GDPR_POLICY_WITHOUT_SLASH,
  JOB_ROUTE,
  JOB_ROUTE_WITHOUT_SLASH,
  ROOT,
  ROOT_WITHOUT_SLASH,
  SURVEY,
  SURVEY_EXPIRED,
  SURVEY_EXPIRED_WITHOUT_SLASH,
  SURVEY_NOT_FOUND,
  SURVEY_NOT_FOUND_WITHOUT_SLASH,
  SURVEY_SUBMITTED,
  SURVEY_SUBMITTED_WITHOUT_SLASH,
  SURVEY_WITHOUT_SLASH
} from 'candidate/shared/paths';
import StructuredData from 'candidate/structured';
import './scss/styles.scss';

const load = makeLoadable({delay: 0, loading: Loader});

const CareersPage = load(() => import(/* webpackPrefetch: true, webpackChunkName: "_careers" */ './careers/index.js'));

const Job = load(() => import(/* webpackPrefetch: true, webpackChunkName: "job" */ './job/index.js'));

const GdprPolicy = load(() => import(/* webpackPrefetch: true, webpackChunkName: "gdpr" */ './gdprPolicy/index.js'));

const Survey = load(() => import(/* webpackChunkName: "survey" */ './job/submit/survey/page.js'));

const SurveySubmitted = load(
  () => import(/* webpackChunkName: "surveySubmitted" */ './job/submit/survey/submitted.js')
);

const SurveyExpired = load(
  () => import(/* webpackChunkName: "surveyExpired" */ './job/submit/survey/expired/index.js')
);

const SurveyNotFound = load(() => import(/* webpackChunkName: "surveyNotFound" */ './errors/surveyNotFound/index.js'));

export const App = ({href = window.location.href, isLoading = window.stillLoading, search = window.location.search}) =>
  !isLoading &&
  (Previews(href) || (
    <BrowserRouter>
      <ErrorBoundary>
        <>
          <PageviewEmitter analytics={analytics} />
          <Providers>
            <StructuredData />
            <Debug />
            <AnalyticsVariables />
            <Layout>
              <Outline />
              <Switch>
                {__ENV__ !== 'production' ? <Route component={ThrowComponent} exact path="/__/throw/:error" /> : null}
                <Redirect exact from={ROOT_WITHOUT_SLASH} strict to={{pathname: ROOT, search}} />
                <Redirect exact from={JOB_ROUTE_WITHOUT_SLASH} strict to={{pathname: JOB_ROUTE, search}} />
                <Redirect exact from={APPLY_ROUTE_WITHOUT_SLASH} strict to={{pathname: APPLY_ROUTE, search}} />
                <Redirect exact from={EEOC_WITHOUT_SLASH} strict to={{pathname: EEOC, search}} />
                <Redirect exact from={GDPR_POLICY_WITHOUT_SLASH} strict to={{pathname: GDPR_POLICY, search}} />
                <Redirect exact from={SURVEY_WITHOUT_SLASH} strict to={{pathname: SURVEY, search}} />
                <Redirect exact from={SURVEY_EXPIRED_WITHOUT_SLASH} strict to={{pathname: SURVEY_EXPIRED, search}} />
                <Redirect
                  exact
                  from={SURVEY_NOT_FOUND_WITHOUT_SLASH}
                  strict
                  to={{pathname: SURVEY_NOT_FOUND, search}}
                />
                <Redirect
                  exact
                  from={SURVEY_SUBMITTED_WITHOUT_SLASH}
                  strict
                  to={{pathname: SURVEY_SUBMITTED, search}}
                />
                <Route component={GdprPolicy} exact path={GDPR_POLICY} />
                <Route component={GdprPolicy} exact path={GDPR_POLICY_WITH_VERSION} />
                <Route component={Job} exact path={APPLY_ROUTE} />
                <Route component={Job} exact path={JOB_ROUTE} />
                <Route component={Filters} exact path={FILTERS} />
                <Route component={Eeoc} exact path={EEOC} />
                <Route component={Survey} exact path={SURVEY} />
                <Route component={SurveySubmitted} exact path={SURVEY_SUBMITTED} />
                <Route component={SurveyExpired} exact path={SURVEY_EXPIRED} />
                <Route component={SurveyNotFound} exact path={SURVEY_NOT_FOUND} />
                <Route component={CareersPage} path={ROOT} />
                <Route component={AccountMissing} path="/" />
              </Switch>
              <CookieConsent />
            </Layout>
            <Attribution />
          </Providers>
        </>
      </ErrorBoundary>
    </BrowserRouter>
  ));

export default hot(App);
